<template>
  <v-dialog :value="showAdDialog" width="600" persistent>
    <v-card style="border-radius: 30px !important;">
      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h2 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('common.adsDialog.title')}}</h2>
            </v-col>
            <v-col cols="1" class="offset-1">
              <!--v-btn icon @click="close(false)">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn-->
            </v-col>
          </v-row>
        </v-col>

        <!-- Body -->
        <v-col cols="8" offset="2" class="mt-6 text-center">
          <p class="font-weight-bold">{{$t('common.adsDialog.description')}}</p>
          <p class="font-weight-bold">{{$tc('common.adsDialog.result-in', secondsLeft,  {seconds: secondsLeft})}}</p>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-col>

        <!-- Ad -->
        <v-col cols="12" class="mt-6" style="min-height: 100px !important;">

        </v-col>

      </v-row>
      <v-card-actions class="text-center">
        <v-btn class="mx-auto font-weight-bold" text @click="close">👉{{$t('common.adsDialog.action')}}👈</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AdsDialog",


  data() {
    return {
      timeout: null,
      interval: null,
      secondsLeft: 5
    }
  },

  mounted() {

  },

  methods: {
    close() {
      this.$store.commit("setShowAdDialog", false);
    },

    init() {
      this.secondsLeft = 5;

      this.interval = setInterval(() => {
        this.secondsLeft--;
      }, 1000);

      this.timeout = setTimeout(() => {
        clearInterval(this.interval);
        this.close();
      }, 5000);
    }
  },

  computed: mapState(['showAdDialog']),

  watch: {
    showAdDialog(newValue) {
      this.init();
    }
  }
}
</script>

<style scoped>

</style>